import React, { useState } from 'react';
import "./index.css";
import logo from "../../../Img/Group 2.png";
import { Link } from 'react-scroll';

function Index(props) {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', changeBackground);

    function showSidebar() {
        let sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'flex';
    }

    function hideSidebar() {
        let sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'none';
    }

    function handleWhatsAppClick() {
        window.open('https://wa.me/message/CJYMAWX7WTNBF1', '_blank');
    }

    return (
        <nav className={navbar ? 'container navbar' : 'container navbar_active'}>
            <Link to="Hero"><img alt="logo" src={logo} className="logo" /> </Link>
            <ul className="check_list">
                <li className="hideOnMobile"><Link to="about"><span>About Us</span></Link></li>
                <li className="hideOnMobile"><Link to="partners"><span>Our Partners</span></Link></li>
                <li className="hideOnMobile"><Link to="services"><span>Services</span></Link></li>
                <li className="hideOnMobile"><Link to="Contacts"><span>Contacts</span></Link></li>
                <li className="hideOnMobile">
                    <a href="#" onClick={handleWhatsAppClick}>
                        <i className="fa-brands fa-whatsapp"></i>
                    </a>
                </li>
                <li className="menu_button" onClick={showSidebar}><a href="#" />
                    <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -1000 800 700" width="30">
                        <path d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" fill="#FFFFFF" />
                    </svg>
                </li>
            </ul>

            <ul className="sidebar">
                <li onClick={hideSidebar}><a href="#" />
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                        <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                    </svg>
                </li>
                <li><Link to="about"><span>About Us</span></Link></li>
                <li><Link to="partners"><span>Our Partners</span></Link></li>
                <li><Link to="services"><span>Services</span></Link></li>
                <li>
                    <Link to="Contacts">
                        <button className="btn">Contacts</button>
                    </Link>
                </li>
                <li>
                    <a href="#" onClick={handleWhatsAppClick}>
                        <i className="fa-brands fa-whatsapp"></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Index;
