import React from 'react';
import "./index.css";
import Logo_Footer from "../../Img/Group 2.png";

function Footer(props) {
    const openSocialMedia = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="footer_container">
            <div className=" section">
                <div className="footer_grid container2">
                    <div className="footer_logo logo1">
                        <img alt="logo" src={Logo_Footer} />
                    </div>
                    <div  className="footer_text text_logo">
                        <p>We are proud of your trust us because our customers’ choice is the best rating for us</p>
                    </div>
                    <div className="footer_subscription email_text">
                        <p>Leave your information, and you'll stay updated on the latest news in IT industry.</p>
                        <div className="footer_input">
                            <input type="email" placeholder="Your email" />
                            <button>Send</button>
                        </div>
                    </div>
                    <div className="footer_social social">
                        <p>Follow us</p>
                        <div className="social_icons">
                            <i className="fa-brands fa-facebook-f" onClick={() => openSocialMedia("https://www.facebook.com/profile.php?id=61556572661076&mibextid=LQQJ4d")}></i>
                            <i className="fa-brands fa-instagram" onClick={() => openSocialMedia("https://www.instagram.com/itbee_?igsh=MWdycXk0cmFqOXZkbw==")}></i>
                            <i className="fa-brands fa-telegram" onClick={() => openSocialMedia("https://t.me/itbee1")}></i>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Footer;
