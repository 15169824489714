import React from 'react';
import vector1 from "../../Img/v1.png";
import vector2 from "../../Img/v2.png";
import vector3 from "../../Img/v3.png";
import vector4 from "../../Img/v4.png";
import "./index.css";

function Index(props) {
    const content = [
        {image: vector1, text1: 'Customer\nSatisfaction', text2: 'Years in the industry'},
        {image: vector2, text1: 'Quality', text2: 'Time-tested proven by real market usage'},
        {image: vector3, text1: 'Instruments', text2: 'Powerful CRM and Risk management tools'},
        {image: vector4, text1: 'Team', text2: 'Always available\nDetails Focused'}
    ];

    return (
        <div className="container_whey">
            <div className="section">
                <div className="container2">
                    <div className="why_title_container">
                        <div className="why_title">
                            <p>Why Choose</p>
                        </div>
                        <div className="itb_text_s">
                            <span>IT BEE</span>
                        </div>
                    </div>

                    <div className="whey_display_grid">
                        {content.map((item, index) => (
                            <div className="whey_item" key={index}>
                                <div className="whey_itm_imag">
                                    <img alt="Whey" src={item.image} />
                                </div>
                                <div className="whey_info">
                                    <span>{item.text1}</span>
                                    <p>{item.text2}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
