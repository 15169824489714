import React from 'react';
import Marquee from 'react-fast-marquee';
import img1 from './../../Img/Part Logo/Vector.png';
import img2 from './../../Img/Part Logo/zoho.png';
import img3 from './../../Img/Part Logo/dsffdv 1.png';
import img4 from './../../Img/Part Logo/edf 1.png';
import img5 from './../../Img/Part Logo/fghbgf.png';
import img7 from './../../Img/Part Logo/Vector(1).png';
import './PartnerSlider.css';

function App() {
    return (
        <div id="partners" className="slider_contanier">
            <div className="section">
                <div className="container2">
                    <div className="slider_block">
                        <div className="title-slider">
                            <h1>Our Partners</h1>
                        </div>

                        <div>
                            <Marquee direction="right" speed={100} delay={5}>
                                <div className="image_wrapper">
                                    <img src={img1} alt=""/>
                                </div>
                                <div className="image_wrapper2">
                                    <img src={img2} alt=""/>
                                </div>
                                <div className="image_wrapper">
                                    <img src={img3} alt=""/>
                                </div>
                                <div className="image_wrapper">
                                    <img src={img4} alt=""/>
                                </div>
                                <div className="image_wrapper">
                                    <img src={img5} alt=""/>
                                </div>
                                <div className="image_wrapper">
                                    <img src={img7} alt=""/>
                                </div>
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;