import React from 'react';
import "./index.css"
import AboutUsePng from "./../../Img/jiip 1.png"

function Index(props) {
    return (
        <div id="about" className="about-section">
            <div className="container2">
                <div className="section2">
                    <div className="about_use_section">
                        <img alt="about_use" src={AboutUsePng}/>
                        <div className="title">
                            <span>ABOUT US</span>
                            <div className="title_p">
                                <p>
                                    We are a team of highly skilled IT professionals, each with our own hive of
                                    expertise,
                                    converging to form a powerhouse of digital solutions. From web development to chat
                                    bots,
                                    CRM systems, and AI, we bring together a wealth of experience to offer top-notch
                                    digital
                                    solutions. What binds us together? Our dedication to excellence and our passion for
                                    staying at the forefront of technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Index;
