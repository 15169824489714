import React from 'react';
import vector1 from './../../Img/v21.png';
import vector2 from './../../Img/Vector (1).png';
import vector3 from './../../Img/Vector (3).png';
import vector4 from './../../Img/Mard.png';
import "./index.css";

function Index(props) {
    const services = [
        { image: vector1, text: 'Data & Analytics' },
        { image: vector2, text: 'Programming and coding' },
        { image: vector3, text: 'Technology & Consulting' },
        { image: vector4, text: 'Outsourcing Chat Bots' }
    ];

    return (
        <div id="services" className="our_services">
            <div className="section">
                <div className="container2">
                    <div className="our_s_title">
                        <p>Our Services</p>
                    </div>

                    <div className="cont_ser">
                        {services.map((service, index) => (
                            <div className="ser_cont" key={index}>
                                <div className="ser_cont_img">
                                    <img className="our_ser_img" alt="vector" src={service.image} />
                                </div>
                                <div className="ser_cont_text">
                                    <p>{service.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
