import './App.css';

import Info from "./Componets/Let_Us";
import Our_Services from "./Componets/Our_Services";
import Footer from "./Componets/Footer/Footer";
import Navbar from "./Componets/Header/Navbar";
import Hero from "./Componets/Header/Hero/hero";
import Partnerc from "./Componets/Slider/Partnerc";
import Registr_Page from "./Componets/Registr_Page";
import AboutUse from "./Componets/AboutUse";
import Whey from "./Componets/Whey";




function App() {
    return (
        <>
            <Navbar/>
            <Hero/>
            <Info/>
            <Our_Services/>
            <AboutUse/>
            <Whey/>
            <Partnerc/>
            <Registr_Page/>
            <Footer/>
        </>
    );
}

export default App;
