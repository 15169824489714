import React, {useState} from "react";
import "./index.css";

const Modal = ({isOpen, onClose}) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="150"
                    height="150"
                    viewBox="0 0 50 50"
                    fill="green"
                >
                    <path
                        d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"></path>
                </svg>
                <h2 className="success-text">You've Successfully Submitted Your Information!!</h2>
                <button onClick={onClose} className="btn1">Ok</button>
            </div>
        </div>
    );
};

function Index(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {firstName, lastName, phoneNumber, email} = formData;

        try {
            const response = await fetch(
                "https://script.google.com/macros/s/AKfycbzhf6YW8nb3st77QBN5gnbt-vsvPsZq3r_UpmA1eJ4MbMStYpy1aEUGUfM0uS9kbLreGQ/exec",
                {
                    method: "POST",
                    body: new URLSearchParams(formData),
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            setIsModalOpen(true);

            const existingPerson = JSON.parse(localStorage.getItem(email));

            if (!existingPerson) {
                localStorage.setItem(
                    email,
                    JSON.stringify({firstName, lastName, phoneNumber, email})
                );
            }

            setFormData({
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
            });
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred. Please try again later.");
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div id="Contacts" className="register_back ">
            <div className="section">
                <div className="container2">
                    <div className="cont_reg ">
                        <div className="reg_title">
                            <div className="text_register">
                                <p>Contact us </p>
                            </div>
                            <div className="text_register_info">
                                <p>
                                    Please provide your details below so we can get in touch with you.
                                </p>
                            </div>
                        </div>

                        <div>
                            <form className="forme" onSubmit={handleSubmit}>
                                <div className="register_container2">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="">First Name</label>
                                    </div>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="">Last Name</label>
                                    </div>
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="">Email</label>
                                    </div>
                                    <div className="input-group">
                                        <input
                                            type="tel"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            required
                                            pattern="[+374]{4}|[0]{1}))?([1-9]{2})(\d{6}"
                                        />
                                        <label htmlFor="">Phone Number</label>
                                    </div>

                                </div>
                                <div className="button2">
                                    <button type="submit" className="button_reg2"> Send</button>
                                </div>
                            </form>
                            <Modal isOpen={isModalOpen} onClose={closeModal}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Index;
