import React from 'react';
import "./hero.css";
import Hero1 from "../../../Img/Hero.png"

function Hero(props) {
    return (
        <>
            <div id="Hero" className="hero">
                <div className="  container2">
                    <div className="hero_cont" id="Hero">
                        <div className=" test8">
                            <div className="hero-content ">
                                <span>Welcome to IT Bee</span>
                                <p className="hero_text_2">Where Innovation Meets Excellence</p>
                                <div className="hero-content_title_1">
                                    <p>
                                        IT Bee is a team of tech enthusiasts, crafting solutions that redefine the
                                        possibilities of technology.
                                        Specializing in website development, chat bot creation, CRM system engagement,
                                        and harnessing the power of artificial intelligence, we're here to propel your
                                        business into the future.
                                    </p>
                                </div>
                            </div>
                            <img className="hero_img" src={Hero1} alt="hero "/>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
