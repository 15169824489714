import React from 'react';
import ".//index.css"

let letUse = [
    {id: 1, letUseNumber: "50+", letUseText1: "Clients", LetUseText2: "From 26 countries"},
    {id: 2, letUseNumber: "5000", letUseText1: "Traders", LetUseText2: "From 26 countries"},
    {id: 3, letUseNumber: "500", letUseText1: "Satisfied Customers", LetUseText2: "From 23 countries"},
]

function Index(props) {
    return (
        <div className="section ">
            <div className="container2">
                <div className="title_let_us">
                    <h1>Let us show you</h1>
                    <h3>Some states</h3>
                </div>
                <div className="let_use_states_text">
                    {letUse.map((letuse, index) => (
                        <div className="" key={index}>
                            <div>
                                <div className="letuse_cont_2">
                                    <p>{letuse.letUseNumber} </p>
                                    <span>{letuse.letUseText1} </span>
                                </div>
                                <div className="letuse_cont_3">
                                    <p>{letuse.LetUseText2}</p>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>

            </div>

        </div>

    );
}

export default Index;